'use client'

import React, { useEffect, useState } from 'react';
import axios from 'axios';

function Popup_form() {
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const hasSeenModal = localStorage.getItem('hasSeenModal');
        if (!hasSeenModal) {
            setTimeout(() => {
                setShowModal(true);
                localStorage.setItem('hasSeenModal', 'true');
            }, 3000);
        }
    }, []);

    useEffect(() => {
        if (showModal) {
            document.body.style.overflow = 'hidden';  // Disable scrolling
        } else {
            document.body.style.overflow = 'auto';  // Restore scrolling
        }

        return () => {
            document.body.style.overflow = 'auto';  // Cleanup on unmount
        };
    }, [showModal]);

    
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent the default form action
        axios.post('https://ananthapuricdc.com/api/contact/', formData)
            .then(response => {
                console.log('Form submitted successfully:', response.data);
                window.location.reload(); // Refresh the page after successful submission
            })
            .catch(error => {
                console.error('Error in form submission:', error.response.data);
            });
      };

    return (
        <div>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="modal-box">
                            <button type="button" className="btn btn-primary btn-lg show-modal" data-bs-toggle="modal" data-bs-target="#myModal" onClick={() => setShowModal(true)} style={{ display: "none" }}>
                                Login Form
                            </button>

                            <div className={`modal fade ${showModal ? 'show' : ''}`} id="myModal" tabIndex="-1" aria-labelledby="myModalLabel" aria-hidden={!showModal} style={{ display: showModal ? 'block' : 'none', background: 'rgba(0,0,0,0.5)' }}>
                                <div className='modal-wrapper'>
                                    <div className="modal-dialog">
                                        <div className="modal-content">

                                            <div className="modal-body">
                                              

                                            <div>
                                                <button type="button" className="btn-close" onClick={() => setShowModal(false)} aria-label="Close">X</button>
                                            </div>
                                                <form onSubmit={handleSubmit} className="contact-form style2 ajax-contact">
                                                    <div className="title-area mb-30">
                                                        <h3 className="sec-title mt-n3">Get In Touch</h3>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12 form-group">
                                                            <input type="text" placeholder="Your Name" className="form-control" name="name" value={formData.name} onChange={handleChange} />
                                                            <i className="fa fa-user"></i>
                                                        </div>
                                                        <div className="col-md-6 form-group">
                                                            <input type="email" placeholder="Mail" className="form-control" name="email" value={formData.email} onChange={handleChange} />
                                                            <i className="fa fa-envelope"></i>
                                                        </div>
                                                        <div className="col-md-6 form-group">
                                                            <input type="tel" className="form-control" name="phone" value={formData.phone} onChange={handleChange} placeholder="Phone"  maxLength="10"  inputMode="numeric" />
                                                            <i className="fa fa-phone"></i>
                                                        </div>
                                                        <div className="form-group col-12">
                                                            <input type="text" className="form-control" name="message" value={formData.message} onChange={handleChange} placeholder="How can I help you" />
                                                            <i className="fa fa-comment"></i>
                                                        </div>
                                                        <div className="col-12 form-group mb-0">
                                                            <button type="submit" className="th-btn btn-fw">Send Message</button>
                                                        </div>
                                                    </div>
                                                    <p className="form-messages mb-0 mt-3"></p>
                                                </form>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Popup_form;
